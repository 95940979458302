import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../../graphqlTypes';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PageTitle from '../../components/PageTitle';
import Banner from '../../components/Banner';
import Grid from '../../components/Grid';
import CenteredBox, { CenteredBoxSizes } from '../../components/CenteredBox';
import { scale } from '../../styled';
import ButtonLink from '../../components/ButtonLink';
import ServicesBlock, { ServiceBlockTypes } from '../../components/ServicesBlock';
import RequestQuoteBlock from '../../components/RequestQuoteBlock';
import ReassuranceBlock from '../../components/ReassuranceBlock';

const BulkyDeliveryPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetBulkyDeliveryPageQuery>(
    graphql`
      query GetBulkyDeliveryPage {
        file(name: { eq: "service-bulky-delivery" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 864, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 500, layout: CONSTRAINED)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox size={CenteredBoxSizes.S}>
        <p style={{ marginBottom: scale(2) }}>
          Comment envoyer un colis volumineux dans la cité des Ducs ? Nous proposons une solution
          rapide et écologique pour l&apos;envoi de colis entre particuliers ou professionnels.
        </p>
        <h2>Un mode de transport au meilleur prix</h2>
        <p>
          Nos différentes formules s&apos;adaptent à votre budget : la livraison à l&apos;étage ou
          la collecte de vos emballages sont en option. Nos coursiers, spécialistes des envois
          volumineux, vous proposent de prendre en charge votre demande dans un délai rapide.
        </p>
        <p>
          <b>Recevoir ou envoyer un colis encombrant n&apos;a jamais été aussi simple.</b>
        </p>
        <p style={{ marginBottom: scale(2) }}>
          <Link to="/contact">Contactez nous sur notre formulaire de contact</Link> en précisant le
          poids et les dimensions de votre objet. Nous vous rappelons rapidement pour un devis.
        </p>
        <Grid>
          <React.Fragment>
            <GatsbyImage
              image={pageData.image_1?.childImageSharp?.gatsbyImageData}
              alt=""
              style={{ maxWidth: '100%', borderRadius: scale(2) }}
            />
          </React.Fragment>
          <React.Fragment>
            <h2>Une solution locale et écologique</h2>
            <p>
              Des machines de l&apos;île au Lieu Unique en passant par le cours des 50 otages, nos
              transporteurs à vélo vous proposent un mode de transport doux et express.
            </p>
            <p>
              Green Course est une entreprise de transport nantaise spécialisée dans l&apos;envoi de
              colis à vélo cargo.
            </p>
          </React.Fragment>
        </Grid>
        <h2>Du matériel adapté aux livraisons jusqu&apos;à 250 kg</h2>
        <p>
          L&apos;acheminement et la manutention{' '}
          <b>d&apos;électroménagers, mobiliers ou encore encombrants</b> sont réalisées par des
          livreurs expérimentés et du matériel sur mesure.
        </p>
        <p style={{ textAlign: 'center', marginBottom: scale(2) }}>
          <ButtonLink to="/contact">Demandez un devis gratuit</ButtonLink>
        </p>
        <GatsbyImage
          image={pageData.image_2?.childImageSharp?.gatsbyImageData}
          alt=""
          style={{ width: '100%', borderRadius: scale(2) }}
        />
      </CenteredBox>
      <ServicesBlock
        title="Nos autres services"
        data-testid="block-services"
        displayedBlockTypes={[ServiceBlockTypes.MailService, ServiceBlockTypes.PackageDelivery]}
      />
      <RequestQuoteBlock data-testid="block-request-quote" />
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
    </Layout>
  );
};

export default BulkyDeliveryPage;
