import React from 'react';

import styled, { scale, BREAKPOINTS } from '../styled';

const Layout = styled.div`
  margin-bottom: ${scale(2)};

  @media (min-width: ${BREAKPOINTS.s}) {
    display: flex;
    align-items: center;
  }
`;

const Left = styled.div<{ width?: number }>`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    width: ${({ width = 45 }): string => `${width}%`};
    margin-bottom: ${scale(0)};
    margin-right: ${scale(1)};
  }
`;

const Right = styled.div<{ width?: number }>`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    width: ${({ width = 55 }): string => `${width}%`};
    margin-bottom: ${scale(0)};
    margin-left: ${scale(1)};
  }
`;

const Grid: React.FC<{ children: JSX.Element[]; widths?: number[] }> = ({
  widths = [],
  children,
}) => {
  return (
    <Layout>
      <Left width={widths[0]}>{children[0]}</Left>
      <Right width={widths[1]}>{children[1]}</Right>
    </Layout>
  );
};

export default Grid;
